.policy-process-successful-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.policy-submission-success-main-text-wrapper {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #131313;
  margin-top: 24px;
}

.download-certificates-button-container {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 30px;
  margin-top: 28px;
}

.download-certificates-button-wrapper {
  display: flex;
  justify-content: center;
}

.download-certificates-button-wrapper button {
  width: 260px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fe0000;
  border-radius: 8px;
  font-family: "Paralucent-stencil-medium";
  font-size: 16px;
  line-height: 19px;
  color: #fffdfd;
  border: none;
}
