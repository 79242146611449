.homepage-container {
  position: relative;
  background-color: #fffdfd;
}

.homepage-container--inner {
  position: relative;
  max-width: 1240px;
  margin: auto;
  padding: 85px 0 50px;
  overflow: hidden;
}

/* hexal-spiral-wrapper */
.hexal-spiral-wrapper {
  position: absolute;
  top: calc(20px + 10vh);
  left: 1px;
}

.hexal-spiral-wrapper img {
  height: 650px;
}

/* Section.homepage-row-one-section */
section.homepage-row-one-section {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  column-gap: 80px;
  margin-top: 65px;
}

/* Left Wrapper */
.homepage-row-one--left-wrapper {
  margin-top: 75px;
}

.marine-policy-frame-wrapper {
  width: 154px;
  height: 34px;
  background: rgba(254, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  line-height: 19px;
  color: #fe0000;
  margin-bottom: 24px;
}

.homepage-row-one-main-text {
  max-width: 635px;
  font-weight: 700;
  font-size: 42px;
  line-height: 51px;
  color: #131313;
}

.homepage-row-one-sub-text {
  max-width: 528px;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  color: #131313;
  margin-top: 16px;
}

/* .home--signup-button-wrapper */
.home-auth-action-buttons-wrapper {
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-top: 48px;
}

.home--signup-button-wrapper button,
.home--signin-button-wrapper button {
  width: 254px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  /* Text style */
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.home--signup-button-wrapper button {
  background: #fe0000;
  border: none;
  color: #fffdfd;
  overflow: hidden;
}

.home--signup-button-wrapper button img {
  margin-left: 7px;
  transition: all 0.5s ease-in-out;
}

.home--signin-button-wrapper button {
  background-color: transparent;
  border: 1.5px solid #fe0000;
  color: #fe0000;
}

/* .home--signup-button-wrapper */
.home--signup-button-wrapper {
  position: relative;
}

.home-sign-up-link-dropdown-wrapper {
  position: absolute;
  top: 32px;
  width: 100%;
  height: 120px;
  max-height: 9999px;
  background-color: #fe0000;
  border-radius: 0 0 10px 10px;
  padding: 0 9px;
  transition: max-height 0.9s cubic-bezier(1, 0, 1, 0);
  overflow: hidden;
}

.signup-child-button-wrapper:nth-child(1) {
  border-bottom: 0.7px solid #fffdfd;
  margin-top: 20px;
}

.signup-child-button-wrapper button {
  width: 100%;
}

.signup-child-button-wrapper button:hover {
  color: rgba(255, 255, 255, 0.85);
  transition: all 0.5s ease-in-out;
}

.home-sign-up-link-dropdown-wrapper.collapsed {
  max-height: 0;
  transition: max-height 0.45s cubic-bezier(1, 0, 1, 0);
}

.rotate-icon {
  transform: rotate(-180deg);
}

/* Media Queries */
@media (min-width: 1390px) {
  .homepage-container--inner {
    max-width: 1290px;
  }
}

@media (min-width: 1490px) {
  .homepage-container--inner {
    max-width: 1380px;
  }
}

@media (min-width: 1590px) {
  .homepage-container--inner {
    max-width: 1480px;
  }
}

@media (max-width: 1310px) {
  .homepage-container--inner {
    padding: 80px 25px 40px;
  }
}

@media (max-width: 840px) {
  .homepage-container--inner {
    padding: 60px 20px 40px;
  }
}
