/* App Table Container */
.app-table-container {
  width: 100%;
  margin-top: 17px;
  overflow-x: auto;
}

.app--table {
  width: 100%;
  background-color: #f0ecf8;
  margin-top: 25px;
  /* Remove table gaps and margins */
  border-spacing: 0px;
}

.app--table thead {
  background: #fff5f5;
}
/* Table Head , Table Row */
.app--table thead tr {
}

/* Table Head , Table Row, Thable header*/
.app--table thead tr th {
  padding: 20px 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #131313;
}

.app--table thead tr th:nth-child(1) {
  padding-left: 50px;
}

/* Table Body */
.app--table tbody {
  margin: 0;
}

.app--table tbody tr {
  overflow: hidden;
}

.app--table tbody tr:hover td {
  background-color: #fffdfd;
}

/* Table Body , Table Data*/
.app--table tbody tr td {
  background-color: #ffffff;
  padding: 18px 10px;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  color: #131313;
  border-bottom: 4px solid #fffdfd !important;
  vertical-align: middle;
}

.app--table tbody tr td > div {
  min-width: 90px;
}
.app--table tbody tr:nth-last-child(1) td {
  border-bottom: none !important;
}

.app--table tbody tr td:nth-child(1) {
  padding-left: 45px;
}

.td-status--wrapper {
  min-width: 97px;
  width: max-content;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 28px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}

.completed-status-wrapper {
  background: rgba(52, 168, 83, 0.1);
  color: #34a853;
}

.in-transit-status-wrapper {
  background: rgba(245, 133, 41, 0.1);
  color: #f2994a;
}

.cancelled-status-wrapper,
.rejected-status-wrapper {
  background: rgba(242, 7, 7, 0.1);
  color: #f20707;
}

.success-text-wrapper {
  color: #34a853;
}

.danger-text-wrapper {
  color: #f20707;
}
