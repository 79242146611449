.change-password-container {
  position: relative;
  padding-bottom: 30px;
}

.change-password-container--inner {
  position: relative;
  max-width: 1380px;
  display: flex;
  margin: auto;
  overflow: hidden;
  padding-right: 60px;
}

/* change-password-container-main-section */
section.change-password-container-main-section {
  position: relative;
  width: 100%;
  background: #fffdfd;
  padding: 0 16px;
  margin-left: 350px;
  margin-top: 130px;
}

.change-password-form-wrapper {
  margin-top: 50px;
}

.change-password-form-wrapper form {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.change-password-form-wrapper .form-group input {
  width: 100%;
}

/* Media queries */
@media (min-width: 1400px) {
  .change-password-container--inner {
    max-width: 1500px;
  }
}

@media (min-width: 1500px) {
  .change-password-container--inner {
    max-width: 1700px;
  }
}
