.companies-container {
  position: relative;
  padding-bottom: 30px;
}

.companies-container--inner {
  position: relative;
  max-width: 1380px;
  display: flex;
  margin: auto;
  overflow: hidden;
  padding-right: 60px;
}

/* companies-container-main-section */
section.companies-container-main-section {
  position: relative;
  width: 100%;
  background: #fffdfd;
  padding: 0 16px;
  margin-left: 350px;
  margin-top: 130px;
}

/* companies Table section */
section.companies-table-section {
  width: 100%;
  padding-bottom: 120px;
}

/* Table Top */
.companies-table--top-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* Table Top Left */
.companies-table--top--left-wrapper {
  display: flex;
  column-gap: 30px;
  align-items: center;
}

.add-company-button-wrapper button,
.invite-company-button-wrapper button {
  width: 165px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;
  background: #fe0000;
  font-size: 16px;
  font-family: "Paralucent-stencil-medium";
  line-height: 20px;
  color: #fffdfd;
  transition: all 0.4s ease-in-out;
}

.invite-company-button-wrapper button {
  margin-left: 12px;
}

.add-company-button-wrapper button:hover,
.invite-company-button-wrapper button:hover {
  background-color: #ff2d2d;
}

/* Companies Table Wrapper */
.td-view-policy-history--wrapper button {
  width: 162px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fe0000;
  border-radius: 10px;
  background-color: inherit;
  font-size: 16px;
  font-family: "Paralucent-stencil-medium";
  line-height: 20px;
  color: #fe0000;
}

/* Media queries */
@media (min-width: 1400px) {
  .companies-container--inner {
    max-width: 1500px;
  }
}

@media (min-width: 1500px) {
  .companies-container--inner {
    max-width: 1700px;
  }
}
