.proceed-with-selected-payment-header-box {
  padding-top: 16px;
  padding-bottom: 10px;
}

.proceed-with-selected-payment-header-box .form-header-box--main-text {
  margin-top: 8px;
}

.payment-method-title-wrapper {
  width: 100%;
  height: 49px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #54565a;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #f1f1f1;
  margin-bottom: 45px;
}

.credit-note-file-upload-container {
  position: relative;
  width: 741px;
  height: 357px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  background: inherit;
  border-radius: 10px;
  overflow: hidden;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgba(254, 0, 0, 0.73)' stroke-width='4' stroke-dasharray='10%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.credit-note-img-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.upload-img-title {
  max-width: 279px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: #54565a;
  margin-top: 5px;
}

.upload-img-title span {
  color: rgba(238, 49, 35, 1);
}

.credit-note-file-upload-container input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.continue-using-paystack-button-wrapper {
  width: 741px;
  height: 76px;
  border-radius: 10px;
  color: #131313;
  border: double 2px transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(90deg, rgba(84, 86, 90, 0.33), rgba(254, 0, 0, 0.29));
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.continue-using-paystack-button-wrapper button {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: inherit;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #131313;
}

.continue-using-paystack-button-wrapper img {
  margin-left: 12px;
}

/* Premium deposit wrapper */
.premium-deposit-withdrawal-wrapper {
  width: 546px;
  min-height: 134px;
  background: #fff5f5;
  border-radius: 12px;
  margin: 0 auto;
  padding-top: 16px;
  color: #131313;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.premium-deposit-withdrawal-wrapper--inner {
  width: 423px;
  height: 140px;
  background: #ffffff;
  border-radius: 8px;
  margin: 16px auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 10px;
}

.premium-deposit-withdrawal-payment-amount-container {
  position: relative;
  width: 100%;
  min-height: 92px;
  margin-top: 16px;
  border-radius: 10px;
  border: double 2px transparent;
  background-image: linear-gradient(#fff5f5, #fff5f5),
    linear-gradient(90deg, rgba(84, 86, 90, 0.33), rgba(254, 0, 0, 0.29));
  background-origin: border-box;
  background-clip: content-box, border-box;
  overflow: hidden;
}

.premium-deposit-withdrawal-payment-amount-container--inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  background-color: inherit;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #131313;
  padding: 24px 0;
}

.premium-deposit-withdrawal-payment-amount-container--inner
  .form-header-box--main-text {
  margin-top: 10px;
}

.gradient-border--box-edge-vector-wrapper {
  top: -1.5px !important;
  left: -1.5px !important;
}

.premium-deposit--wallet-balance-title-wrapper {
  font-size: 16px;
  line-height: 22px;
}

.premium-deposit--wallet-balance-value-wrapper {
  font-weight: 900;
  font-size: 24px;
  line-height: 31px;
  margin-top: 8px;
}

.proceed-to-pay-in-arrears-button-wrapper {
  margin: 30px 0 20px;
  display: flex;
  align-items: center;
}

.credit-note-file-preview-btn-wrapper {
  display: flex;
  column-gap: 30px;
  margin-bottom: 10px;
}

.approve-payment-deduction-button-wrapper button,
.proceed-to-pay-in-arrears-button-wrapper button,
.credit-note-file-preview-btn-wrapper button {
  width: 231px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fe0000;
  border: none;
  border-radius: 10px;
  font-family: "Paralucent-stencil-medium";
  font-size: 16px;
  line-height: 20px;
  color: #fffdfd;
  margin-top: 8px;
}

.proceed-to-pay-in-arrears-button-wrapper button {
  width: 260px;
}

.policy-plan-selection-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
