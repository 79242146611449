.reports-container {
  position: relative;
  padding-bottom: 30px;
}

.reports-container--inner {
  position: relative;
  max-width: 1380px;
  display: flex;
  margin: auto;
  overflow: hidden;
  padding-right: 60px;
}

/* reports-container-main-section */
section.reports-container-main-section {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background: #fffdfd;
  margin-left: 350px;
  padding: 0 16px;
  margin-top: 130px;
}

.reports-form-wrapper {
  margin-top: 50px;
}

.reports-form-wrapper form {
  /* max-width: 500px; */
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.reports-form-group-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 35px;
  row-gap: 40px;
  flex-shrink: 0;
}

.reports-form-wrapper .form-group {
  width: 100%;
  max-width: 400px;
}

.reports-form-wrapper .report-type-form-group {
  max-width: 840px;
}

.reports-form-wrapper .form-group input,
.reports-form-wrapper .form-group select {
  width: 100%;
}

.generate-report-form-button-wrapper {
  max-width: 250px;
}

/* Media queries */
@media (min-width: 1400px) {
  .reports-container--inner {
    max-width: 1500px;
  }
}

@media (min-width: 1500px) {
  .reports-container--inner {
    max-width: 1700px;
  }
}
