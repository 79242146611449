.loader-container > .spinner-container {
  padding: 10px 0;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
}

@keyframes ldio-ths3jlz8hwg {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-ths3jlz8hwg div {
  box-sizing: border-box !important;
}
.ldio-ths3jlz8hwg > div {
  position: absolute;
  width: 36px;
  height: 36px;
  top: 7px;
  left: 7px;
  border-radius: 50%;
  border: 4px solid #000;
  border-color: #fd5e5e transparent #fd5e5e transparent;
  animation: ldio-ths3jlz8hwg 1s linear infinite;
}
.ldio-ths3jlz8hwg > div:nth-child(2) {
  border-color: transparent;
}
.ldio-ths3jlz8hwg > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-ths3jlz8hwg > div:nth-child(2) div:before,
.ldio-ths3jlz8hwg > div:nth-child(2) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  top: -4px;
  left: 12px;
  background: #fd5e5e;
  border-radius: 50%;
  box-shadow: 0 128px 0 0 #fd5e5e;
}
.ldio-ths3jlz8hwg > div:nth-child(2) div:after {
  left: -4px;
  top: 12px;
  box-shadow: 32px 0 0 0 #fd5e5e;
}
.loadingio-spinner-dual-ring-tk7n0yezor {
  width: 50px;
  height: 50px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-ths3jlz8hwg {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-ths3jlz8hwg div {
  box-sizing: content-box;
}
