.form-group {
  position: relative;
}

.form-group input {
  height: 57px;
  border: 0.6px solid #131313;
  border-radius: 15px;
  /* outline: none; */
  display: flex;
  align-items: center;
  padding: 0 35px;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  color: #131313;
}

.form-group span.placeholder {
  position: absolute;
  opacity: 1;
  margin: 19px 0;
  padding: 0 9px 0 15px;
  display: flex;
  align-items: center;
  top: 0;
  left: 20px;
  transition: all 0.2s;
  background: none;
  pointer-events: none;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  color: #131313;
}

.disabled-text-input {
  cursor: not-allowed;
}

.form-group input:valid + span.placeholder,
.form-group input:focus + span.placeholder,
.form-group input[type="date"] + span.placeholder,
.disabled-text-input + span.placeholder {
  transform: scale(0.9) translateY(-30px) translateX(20px);
  background: #fff !important;
}

.form-group input:focus {
  outline: 0.1px solid #131313;
}

.form-group input:focus + span.placeholder,
.disabled-text-input + span.placeholder {
  color: #808080;
}
