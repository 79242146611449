.policy-form-three-header-box-wrapper {
  position: relative;
  width: 484px;
  min-height: 92px;
  height: max-content;
  background: #fff5f5;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #131313;
  margin: 0 auto;
  overflow: hidden;
}

.form-header-box--sub-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #131313;
}

/* sum-insured-info-button-wrapper */
.sum-insured-info-button-wrapper {
  position: absolute;
  top: 10px;
  right: 15px;
}

.sum-insured-info-button-wrapper button {
  background-color: inherit;
  border: none;
}

.sum-insured-info-button-wrapper button svg {
  color: #fe0000;
  transform: scale(1.5);
}

/* policy-plan-selection-container */
.policy-plan-selection-container {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.policy-plan-selection-title-wrapper {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: #131313;
}

/* policy-plan-selection-boxes-wrapper */
.policy-plan-selection-boxes-wrapper {
  display: flex;
  align-items: center;
  column-gap: 32px;
  margin: 0 auto;
  margin-top: 24px;
}

.policy-plan-box-wrapper {
  position: relative;
  width: 226px;
  height: 242px;
  margin-top: 16px;
  border-radius: 10px;
  border: double 2px transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(90deg, rgba(84, 86, 90, 0.33), rgba(254, 0, 0, 0.29));
  background-origin: border-box;
  background-clip: content-box, border-box;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.policy-plan-box-wrapper--inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  background-color: inherit;
  padding: 36px 18px 20px;
}

.policy-plan-box-wrapper:hover {
  background-image: none;
  background-color: #fff5f5;
}

.plan-name-wrapper {
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: #131313;
}

.plan-price-wrapper {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #131313;
  margin-top: 16px;
}

.buy-plan-button-wrapper {
  margin-top: 65px;
}

.buy-plan-button-wrapper button {
  width: 181px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fe0000;
  border-radius: 10px;
  border: none;
  font-family: "Paralucent-stencil-medium";
  font-size: 16px;
  line-height: 20px;
  color: #fffdfd;
}

.buy-plan-button-wrapper button:disabled {
  cursor: not-allowed;
}

.disabled-plan-box-wrapper {
  opacity: 0.5;
}

.currency {
  font-family:Verdana, Geneva, Tahoma, sans-serif;
}
