.app-top-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: #fffdfd;
  font-family: "Paralucent";
}

.app-top-container--inner {
  max-width: 1380px;
  height: 130px;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 0;
  padding-right: calc(60px + 0px);
  /* border-bottom: 1px solid red; */
}

.app-top--left-wrapper {
  margin-left: 366px;
}

.top-hello-message-wrapper {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: #000000;
}

.top-page-summary-message {
  font-size: 20px;
  font-weight: 300;
  line-height: 23px;
  color: #828282;
}

/* .app-top--right-wrapper */
.app-top--right-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 30px;
  margin-right: 10px;
}

.onboard-button-wrapper button,
.change-password-button-wrapper button {
  width: 163px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fe0000;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #fffdfd;
}

.notification-button-wrapper button,
.power-button-wrapper button {
  padding: 0;
  background-color: inherit;
  border: none;
}

.user-guide-button-wrapper a {
  width: 200px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fe0000;
  border-radius: 10px;
  background-color: inherit;
  font-size: 16px;
  font-family: "Paralucent-stencil-medium";
  line-height: 20px;
  color: #fe0000;
  text-decoration: none;
}

/* Media queries */
@media (min-width: 1400px) {
  .app-top-container--inner {
    max-width: 1500px;
  }
}

@media (min-width: 1500px) {
  .app-top-container--inner {
    /* max-width: 1690px; */
    max-width: 1700px;
  }
}
