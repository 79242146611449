.box-edge-vector-wrapper {
  position: absolute;
  top: 0;
  left: 0;
}

/* payment-method-selection-boxes-wrapper */
.payment-method-selection-boxes-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: 30px;
}

.payment-method-button-box-wrapper {
  width: 741px;
  height: 76px;
  border-radius: 10px;
  color: #131313;
  border: double 2px transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(90deg, rgba(84, 86, 90, 0.33), rgba(254, 0, 0, 0.29));
  background-origin: border-box;
  background-clip: content-box, border-box;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.payment-method-button-box-wrapper--inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: inherit;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #131313;
}

.payment-method-button-box-wrapper:hover {
  border-radius: 10px;
  background-image: none;
  background-color: #fff5f5;
}
