.onboarding-form-page-container {
}

.onboarding-form-page-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.onboarding--back-button-wrapper button {
  background-color: inherit;
  border: none;
}

.page-number-wrapper {
  width: 44px;
  height: 36px;
  background: #f1f1f1;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  line-height: 22px;
  color: #54565a;
}

/* Onboarding form wrapper */
.onboarding-form-wrapper {
  margin-top: 30px;
}

.onboarding-form-wrapper .form-group {
  width: 444px;
}

.onboarding-form-wrapper .form-group input {
  width: 100%;
}

.onboarding-form-title-wrapper {
  font-family: "Paralucent-stencil-medium";
  font-size: 20px;
  line-height: 25px;
  color: #54565a;
}

/* onboarding-form-page-one--row-one */
.onboarding-form-page-one--row-one,
.name-of-directors-form-group-wrapper,
.onboarding-form-page-one--row-three--inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 30px;
  row-gap: 30px;
  flex-wrap: wrap;
}

.onboarding-form-page-one--row-one,
.onboarding-form-page-one--row-two {
  margin-top: 24px;
}

.onboarding-form-page-one--row-three {
  margin-top: 40px;
}

.onboarding-form-page-one--row-three--inner {
  margin-top: 30px;
}

.smaller-address-input-form-group-wrapper {
}

.street-form-group,
.street-no-form-group,
.post-code-form-group {
  width: 280px !important;
}

.onboarding-form--sub-title-wrapper {
  font-weight: 500;
  line-height: 19px;
  color: #131313;
}

/* name-of-directors-form-group-wrapper */
.name-of-directors-form-group-wrapper {
  margin-top: 24px;
}

/* name-of-directors-form-group */
.name-of-directors-form-group {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.add-new-director-button-wrapper button {
  width: 29px;
  height: 32px;
  background-color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fe0000;
  border-radius: 8px;
}

.add-new-director-button-wrapper button img {
  height: 17px;
}

/* Form Page Bottom Wrapper */
.onboarding-form-page-bottom-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 120px;
}

.field-are-mandatory-wrapper {
  font-weight: 300;
  line-height: 19px;
  color: #54565a;
}

/* submit-onboarding-form-button-wrapper */
.submit-onboarding-form-button-wrapper {
  display: flex;
  align-items: center;
}

.submit-onboarding-form-button-wrapper button {
  width: 245px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fe0000;
  border-radius: 10px;
  border: none;
  font-family: "Paralucent-stencil-medium";
  font-size: 16px;
  line-height: 20px;
  color: #fffdfd;
}

/*  */
/*  */
/*  */
/*  */
.onboarding-form-wrapper
  .form-group
  input[type="number"]::-webkit-inner-spin-button,
.onboarding-form-wrapper
  .form-group
  input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
}

/* MEDIA QUERIES */
@media (max-width: 1360px) {
  .onboarding-form-wrapper .form-group {
    width: 380px;
  }

  .street-form-group,
  .street-no-form-group,
  .post-code-form-group {
    width: 220px !important;
  }
}

@media (max-width: 1240px) {
  .onboarding-form-wrapper .form-group {
    width: 340px;
  }
}

@media (max-width: 1155px) {
  .onboarding-form-wrapper .form-group {
    width: 310px;
  }
}

@media (max-width: 1095px) {
  .onboarding-form-wrapper .form-group {
    max-width: 480px;
    width: 100%;
  }
}
