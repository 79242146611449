.form-group {
  position: relative;
}

.form-group select {
  height: 57px;
  border: 0.6px solid #131313;
  border-radius: 15px;
  /* outline: none; */
  display: flex;
  align-items: center;
  padding: 0 35px;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  color: #131313;
}

.disabled-select-input {
  cursor: not-allowed;
}

.form-group select:valid + span.placeholder,
.form-group select:focus + span.placeholder,
.disabled-select-input + span.placeholder {
  transform: scale(0.9) translateY(-30px) translateX(20px);
  background: #fff !important;
}

.form-group select:focus {
  outline: 0.1px solid #131313;
}

.form-group select:focus + span.placeholder,
.disabled-select-input + span.placeholder {
  color: #808080;
}

.form-group .dropdown-icon img {
  position: absolute;
  height: 6px;
  top: 26px;
  right: 30px;
  z-index: 1;
  pointer-events: none;
}
