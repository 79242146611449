/* General */
.modal-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-title--row-wrapper {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #1d1610;
}

.close-modal-button-wrapper button {
  background-color: inherit;
  border: none;
}

.modal-dialog {
  width: 100%;
  margin: auto;
  font-family: "Paralucent";
}

/* Custom */
.certificate_info_modal_container.modal-dialog {
  max-width: 860px;
}

.certificate_info_modal_container .modal-content {
  min-height: 276px;
  box-sizing: border-box;
  padding: 26px 30px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: 0px 2px 20px rgba(181, 181, 181, 0.15);
  border-radius: 12px;
}

.certificate_info_modal_container .certificate_info_container {
  width: 100%;
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 40px;
  margin: 40px 0 20px;
}

.certificate-info--key-value-wrapper {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.certificate-info--key-wrapper {
  width: 125px;
  color: #1d1610;
  font-size: 15px;
}

.certificate-info--value-wrapper {
  width: 110px;
  word-wrap: break-word;
}

/* Media Queries */
@media (max-width: 720px) {
  .certificate_info_modal_container .modal-content {
    padding: 18px 20px;
  }
}
