.policy-type-selection-box-wrapper {
  position: relative;
  width: 402px;
  min-height: 185px;
  margin-top: 16px;
  border-radius: 12px;
  border: double 2px transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(90deg, rgba(84, 86, 90, 0.33), rgba(254, 0, 0, 0.29));
  background-origin: border-box;
  background-clip: content-box, border-box;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.policy-type-selection-box-wrapper--inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  background-color: inherit;
  padding: 24px 18px;
}

.policy-type-selection-box-wrapper:hover {
  background-image: none;
  background-color: #fff5f5;
}
.policy-type-icon-wrapper {
  margin-bottom: 10px;
}

.policy-type-name-wrapper {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  color: #131313;
}

.choose-policy-type-button-wrapper {
  margin-top: 32px !important;
}
