.signup--upload-file-form-group {
  width: 100%;
  min-height: 77px;
  max-height: 9999px;
  height: max-content;
  background: #ffffff;
  border: 1px solid rgba(19, 19, 19, 0.2);
  border-radius: 10px;
  padding: 15px 33px 22px;
}

.signup--upload-file-form-group:nth-child(1) {
  margin: 25px 0 12px;
}

.signup--upload-file-form-group {
  margin-bottom: 12px;
}

.signup--upload-file-form-group:nth-last-child(1) {
  margin-bottom: 0;
}

.upload-file-form-group--top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-input-title {
  font-weight: 500;
  font-size: 16px;
  color: #131313;
}

.upload-input-wrapper {
  position: relative;
  width: 231px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #fe0000;
  border-radius: 10px;
}

.upload-input-wrapper input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 500;
  line-height: 20px;
  color: #fe0000;
  cursor: pointer;
  opacity: 0;
}

.upload-input-wrapper label {
  color: #fe0000;
}

/* file-upload-progress-bar-wrapper */
.file-upload-progress-bar-wrapper {
  /* justify-content: space-between; */
  display: flex;
  column-gap: 12px;
  margin: 15px 0 0px;
}

.linear-progress-bar-wrapper {
  max-width: 500px;
  width: 100%;
  height: max-content;
  padding: 2px 4px;
  background-color: rgba(19, 19, 19, 0.7);
  border-radius: 20px;
}

.css-5xe99f-MuiLinearProgress-bar1 {
  background-color: rgba(39, 174, 96, 0.9) !important;
}

.css-r40f8v-MuiTypography-root {
  font-family: "Paralucent" !important;
  font-weight: 500 !important;
}

.view-uploaded-file-wrapper a {
  color: rgba(0, 0, 0, 0.7);
}
