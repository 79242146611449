.sum-insured-breakdown-modal-container {
  position: absolute;
  left: 40%;
  top: 20%;
  width: 100%;
  max-width: 550px;
  min-height: 350px;
  z-index: 50;
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: 0px 2px 20px rgba(181, 181, 181, 0.15);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 25px 35px;
}

.sum-insured-breakdown-modal-container--inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cancel-modal-button-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.cancel-modal-button-wrapper button {
  background-color: inherit;
  border: none;
}

.cancel-modal-button-wrapper button img {
  color: rgba(0, 0, 0, 0.7);
  width: 24px;
  height: 24px;
}

.modal-title-wrapper {
  margin-top: 35px;
  font-size: 18px;
  font-weight: 600;
}

.breakdown-container {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-top: 40px;
}

.breakdown-wrapper {
  display: flex;
  column-gap: 70px;
}

.breakdown-title-wrapper {
  width: 160px;
  display: flex;
  justify-content: flex-start;
}
.breakdown-title-wrapper,
.breakdown-value-wrapper {
}
