.sidebar-container {
  position: fixed;
  top: 0;
  background-color: #ffffff;
  z-index: 40;
  /* left: 0; */
}

.sidebar-container--inner {
  display: flex;
  flex-direction: column;
  padding: 50px 0px 0px;
  width: 350px;
  min-height: 100vh;
}

.sidebar--company-logo-wrapper {
  padding-left: 45px;
}

/* Navigation Links Container */
.navigation-links-container {
  margin-top: 90px;
}

.nav-link-container {
  margin-bottom: 10px;
}

.nav-link-container a {
  position: relative;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 10px 0 45px;
  margin-bottom: 4px;
  background: inherit;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: #828282;
}

.nav-link-container a img {
  padding-right: 20px;
}

.active-link-wrapper a {
  background: linear-gradient(
    270.14deg,
    #fd5e5e 4.6%,
    rgba(253, 94, 94, 0) 104.37%
  );
  color: #131313;
}

.active-link-wrapper a::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 9px;
  height: 100%;
  background: #ee3123;
}

.logout-button-wrapper {
  position: absolute;
  bottom: 30px;
  padding-left: 40px;
}
.logout-button-wrapper button {
  display: flex;
  align-items: center;
  border: none;
  background-color: inherit;
  font-size: 17px;
  color: #555555;
}

.logout-button-wrapper img {
  padding-right: 10px;
}
