.onboarding-status-table-container {
}

/* Table Edit Button Wrapper */
.td-edit-button--wrapper button {
  width: 110px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fe0000;
  border-radius: 10px;
  background-color: inherit;
  font-size: 16px;
  font-family: "Paralucent-stencil-medium";
  line-height: 20px;
  color: #fe0000;
}

.td-query-reason--wrapper {
  font-size: 14px;
  max-width: 200px;
  word-wrap: break-word;
}
