/* form-details-summary-container */
.onboarding-form-details-summary-container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: 20px;
}

.form-details-summary-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 60px;
}

.form-detail-title {
  width: 250px;
  font-weight: 300;
  line-height: 18px;
  color: #131313;
}

.form-detail-edit-wrapper {
  /* max-width: 417px; */
  width: 100%;
  height: 54px;
  background: #fff5f5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px 0 48px;
  font-weight: 500;
  line-height: 22px;
}

.file-form-detail-edit-wrapper {
  padding: 0 25px 0 28px;
}

.form-detail-value-wrapper {
  display: flex;
  align-items: center;
}

.form-detail-value-wrapper a {
  text-decoration: none;
  color: #131313;
}

.file-img-wrapper {
  margin-right: 7px;
  padding-right: 10px;
  border-right: 0.7px solid #54565a;
}

.file-img-wrapper svg {
  color: #54565a;
  transform: scale(1.2);
}

.edit-input-button-wrapper button {
  width: 95px;
  height: 40px;
  background: #fffdfd;
  color: #fe0000;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 15px;
  color: #fe0000;
  text-decoration: underline;
}

/* directors-name-edit-wrapper */
.directors-name-edit-wrapper {
  flex-direction: column;
  height: min-content;
  max-height: 9999px;
  padding-bottom: 10px;
}

.directors-name-detail-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 0;
  margin-top: 6px;
  border-bottom: 1px solid rgba(84, 86, 90, 0.3);
}

.directors-name-detail-row:nth-last-child(1) {
  border-bottom: none;
}

.id-card--file-form-detail-edit-wrapper {
  display: flex;
  row-gap: 10px;
  flex-direction: column;
  height: min-content;
  max-height: 9999px;
  padding-bottom: 10px;
}

.id-card--form-detail-edit-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
