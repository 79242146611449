.marine-policy-container {
  position: relative;
  padding-bottom: 50px;
  background-color: #ffffff;
}

.marine-policy-container--inner {
  position: relative;
  min-height: 100vh;
  max-width: 1380px;
  display: flex;
  margin: auto;
  overflow: hidden;
  padding-right: 60px;
}

/* marine-policy-container-main-section */
section.marine-policy-container-main-section {
  position: relative;
  width: 100%;
  background: #fffdfd;
  margin-left: 350px;
  padding: 0 16px;
  margin-top: 130px;
}

/* Media queries */
@media (min-width: 1400px) {
  .marine-policy-container--inner {
    max-width: 1500px;
  }
}

@media (min-width: 1500px) {
  .marine-policy-container--inner {
    max-width: 1700px;
  }
}
