.pagination-section {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff5f5;
  border-radius: 8px 8px 0px 0px;
  margin-top: 20px;
  padding: 0 16px;
}

/* pages-number-count-container */
.pages-number-count-container,
.go-to-page-number-container {
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: #a1a7b3;
}

.pages-number-count-container span {
  font-weight: 500;
  color: #54565a;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
}

.pagination-container nav {
  display: flex;
  align-items: center;
}

.pagination__ {
  display: flex;
  padding: 0 10px;
}

.pagination__ li {
  list-style-type: none;
  margin: 0 5px;
  /* height: max-content !important; */
}

.pagination__ li:first-child {
  margin-left: 0 !important;
}

.pagination__ li button {
  width: 25px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  font-size: 15px;
  font-weight: 500;
  font-family: "Paralucent";
  color: #a1a7b3;
  line-height: 18px;
  text-align: center;
  border: none;
}

.pagination__ li.active button {
  color: #131313;
  border-radius: 5px;
}

.prev-button-wrapper button,
.next-button-wrapper button {
  display: flex;
  align-items: center;
  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
}

.prev-button-wrapper button img,
.next-button-wrapper button img {
  height: 13px;
}

/* go-to-page-number-container */
.go-to-page-number-container {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.select-go-to-page-input-wrapper input {
  width: 27px;
  height: 26px;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 2px;
  font-size: 15px;
}

.select-go-to-page-input-wrapper input:focus {
  outline: 1px solid #a1a7b3;
}

/* Chrome, Safari, Edge, Opera */
.select-go-to-page-input-wrapper input::-webkit-outer-spin-button,
.select-go-to-page-input-wrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.select-go-to-page-input-wrapper input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 767px) {
  li:first-child,
  li.active,
  li.active-sibling:nth-last-child(2),
  li:last-child {
    display: inline-block !important;
  }
  li:first-child:nth-last-child(n + 6) ~ li {
    display: none;
  }
  li:first-child:nth-last-child(n + 6) ~ li:nth-last-child(-n + 3) {
    display: inline-block;
  }
  li:first-child:nth-last-child(n + 6) ~ li:nth-last-child(3):before {
    content: "\2026";
    font-size: 24px;
    display: inline-block;
    margin-right: 2.5px;
    margin-left: 2.5px;
  }
  li:first-child:nth-last-child(n + 6).active:before,
  li:first-child:nth-last-child(n + 6) ~ li.active:before {
    content: "\2026";
    font-size: 24px;
    display: inline-block;
    margin-right: 2.5px;
    margin-left: 2.5px;
  }
  li:first-child:nth-last-child(n + 6).active:after,
  li:first-child:nth-last-child(n + 6) ~ li.active:after {
    content: "\2026";
    font-size: 24px;
    display: inline-block;
    margin-left: 2.5px;
    margin-right: 2.5px;
  }
  li:first-child:nth-last-child(n + 6).active:nth-child(-n + 2):before,
  li:first-child:nth-last-child(n + 6) ~ li.active:nth-child(-n + 2):before,
  li:first-child:nth-last-child(n + 6).active:nth-last-child(-n + 2):before,
  li:first-child:nth-last-child(n + 6)
    ~ li.active:nth-last-child(-n + 2):before,
  li:first-child:nth-last-child(n + 6).active:nth-child(-n + 2):after,
  li:first-child:nth-last-child(n + 6) ~ li.active:nth-child(-n + 2):after,
  li:first-child:nth-last-child(n + 6).active:nth-last-child(-n + 2):after,
  li:first-child:nth-last-child(n + 6)
    ~ li.active:nth-last-child(-n + 2):after {
    display: none;
  }
  li:first-child:nth-last-child(n + 6).active ~ li:nth-last-child(-n + 3),
  li:first-child:nth-last-child(n + 6) ~ li.active ~ li:nth-last-child(-n + 3) {
    display: none;
  }
  li:first-child:nth-last-child(n + 6).active ~ li:nth-child(-n + 3),
  li:first-child:nth-last-child(n + 6) ~ li.active ~ li:nth-child(-n + 3) {
    display: inline-block;
  }
  li:first-child:nth-last-child(n + 6).active ~ li:nth-child(-n + 2):after,
  li:first-child:nth-last-child(n + 6)
    ~ li.active
    ~ li:nth-child(-n + 2):after {
    display: none;
  }
  li:first-child:nth-last-child(n + 6).active ~ li:nth-child(3):after,
  li:first-child:nth-last-child(n + 6) ~ li.active ~ li:nth-child(3):after {
    content: "\2026";
    font-size: 24px;
    display: inline-block;
    margin-left: 2.5px;
    margin-right: 2.5px;
  }
}

@media (min-width: 768px) {
  li:first-child,
  li.active-sibling,
  li.active,
  li.active + li,
  li:last-child {
    display: flex !important;
    align-items: center;
  }
  li:first-child:nth-last-child(n + 8) ~ li {
    display: none;
  }
  li:first-child:nth-last-child(n + 8) ~ li.active-sibling:before {
    content: "\2026";
    font-size: 24px;
    display: inline-block;
    margin-right: 2.5px;
    margin-left: 2.5px;
  }
  li:first-child:nth-last-child(n + 8) ~ li.active + li:after {
    content: "\2026";
    font-size: 24px;
    display: inline-block;
    margin-left: 2.5px;
    margin-right: 2.5px;
  }
  li:first-child:nth-last-child(n + 8) ~ li:nth-last-child(-n + 5) {
    display: flex;
    align-items: center;
  }
  li:first-child:nth-last-child(n + 8) ~ li:nth-last-child(5):before {
    content: "\2026";
    font-size: 24px;
    display: inline-block;
    margin-right: 2.5px;
    margin-left: 2.5px;
  }
  li:first-child:nth-last-child(n + 8) ~ li:nth-child(-n + 2):before,
  li:first-child:nth-last-child(n + 8) ~ li:nth-last-child(-n + 2):before,
  li:first-child:nth-last-child(n + 8)
    ~ li.active-sibling:nth-last-child(-n + 4):before,
  li:first-child:nth-last-child(n + 8) ~ li:nth-child(-n + 2):after,
  li:first-child:nth-last-child(n + 8) ~ li:nth-last-child(-n + 2):after,
  li:first-child:nth-last-child(n + 8)
    ~ li.active-sibling:nth-last-child(-n + 4):after {
    display: none !important;
  }
  li:first-child:nth-last-child(n + 8).active ~ li:nth-last-child(-n + 5),
  li:first-child:nth-last-child(n + 8) ~ li.active ~ li:nth-last-child(-n + 5) {
    display: none;
  }
  li:first-child:nth-last-child(n + 8).active
    ~ li:nth-last-child(-n + 5):before,
  li:first-child:nth-last-child(n + 8)
    ~ li.active
    ~ li:nth-last-child(-n + 5):before {
    display: none;
  }
  li:first-child:nth-last-child(n + 8).active ~ li:nth-child(-n + 5),
  li:first-child:nth-last-child(n + 8) ~ li.active ~ li:nth-child(-n + 5) {
    display: flex;
    align-items: center;
  }
  li:first-child:nth-last-child(n + 8).active ~ li:nth-child(-n + 4):after,
  li:first-child:nth-last-child(n + 8)
    ~ li.active
    ~ li:nth-child(-n + 4):after {
    display: none;
  }
  li:first-child:nth-last-child(n + 8).active ~ li:nth-child(5):after,
  li:first-child:nth-last-child(n + 8) ~ li.active ~ li:nth-child(5):after {
    content: "\2026";
    font-size: 24px;
    display: inline-block;
    margin-left: 2.5px;
    margin-right: 2.5px;
  }
  li:first-child:nth-last-child(n + 8).active:before,
  li:first-child:nth-last-child(n + 8) ~ li.active:before,
  li:first-child:nth-last-child(n + 8).active:after,
  li:first-child:nth-last-child(n + 8) ~ li.active:after {
    display: none;
  }
}
