.signin-container {
  position: relative;
  background-color: #fffdfd;
}

.signin-container--inner {
  position: relative;
  max-width: 1240px;
  margin: auto;
  padding: 85px 0 50px;
  display: flex;
  justify-content: space-between;
  column-gap: 56px;
  overflow: hidden;
}

/* section.auth-left-section */
section.auth-left-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
}

.auth-left--primary-text-line {
  font-weight: 300;
  font-size: 34px;
  line-height: 39px;
  color: #131313;
}

.auth-left--secondary-text-line {
  max-width: 576px;
  font-weight: 700;
  font-size: 38px;
  line-height: 46px;
  color: #131313;
  margin-top: 10px;
}

.auth-left--secondary-text-line span {
  color: #fe0000;
}

/* section.auth-right-section */
section.auth-right-section {
  margin-top: 50px;
}

/* .auth-form-box-container */
.auth-form-box-container {
  width: 684px;
  min-height: 535px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 4px rgba(231, 105, 106, 0.12);
  border-radius: 12px;
  padding: 24px 0;
  margin-right: 8px;
}

.auth-welcome-wrapper {
  display: flex;
  justify-content: center;
  font-weight: 300;
  font-size: 22px;
  line-height: 26px;
  color: #131313;
}

.auth-title-wrapper {
  max-width: 465px;
  margin: 7px auto 0;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff5f5;
  border-radius: 8px;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  color: #131313;
}

.form-progress-line-wrapper {
  width: 25%;
  height: 2px;
  display: flex;
  background-color: #ffb9b4;
  opacity: 0.6;
  margin-top: 15px;
}

.signin-form-wrapper,
.signup-form-wrapper {
  /* max-height: 420px;
  overflow-y: auto; */
}

.signin-form-wrapper::-webkit-scrollbar-track,
.signup-form-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fdfdfd;
}

.signin-form-wrapper::-webkit-scrollbar,
.signup-form-wrapper::-webkit-scrollbar {
  width: 6px;
  background-color: #fdfdfd;
}

.signin-form-wrapper::-webkit-scrollbar-thumb,
.signup-form-wrapper::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.1);
}

.signin-form-wrapper form,
.signup-form-wrapper form {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  max-width: 522px;
}

.signup-form-wrapper.stage-three-form-wrapper form {
  max-width: 600px;
  row-gap: 12px;
}

.signin-form-wrapper form {
  margin: 92px auto 0;
}

.signup-form-wrapper form {
  margin: 32px auto 0;
}

.signin-form-wrapper .form-group input,
.signup-form-wrapper .form-group input {
  width: 100%;
}

.password-icon-wrapper {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 33%;
  right: 30px;
  cursor: pointer;
}

.forgot-password-link-wrapper {
  display: flex;
  justify-content: flex-end;
  transform: translateY(-20px);
}

.forgot-password-link-wrapper a {
  color: #fe0000;
}

/* submit-auth-form-button-wrapper */
.submit-auth-form-button-wrapper {
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.submit-auth-form-button-wrapper button {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fe0000;
  border-radius: 10px;
  border: none;
  font-family: "Paralucent-stencil-medium";
  font-size: 16px;
  line-height: 20px;
  color: #fffdfd;
}

.havent-signup-yet-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  font-weight: 500;
  line-height: 20px;
  color: #131313;
}

.havent-signup-yet-wrapper a {
  display: inline-block;
  margin-left: 4px;
  text-decoration: none;
  color: #fe0000;
  border-bottom: 2px solid #fe0000;
  padding-bottom: 0.1px;
}

.auth-loading-spinner-wrapper {
  margin-left: 5px;
}

/* Media Queries */
@media (min-width: 1390px) {
  .signin-container--inner {
    max-width: 1290px;
  }
}

@media (min-width: 1490px) {
  .signin-container--inner {
    max-width: 1380px;
  }
}

@media (min-width: 1590px) {
  .signin-container--inner {
    max-width: 1480px;
  }
}

@media (max-width: 1310px) {
  .signin-container--inner {
    padding: 80px 25px 40px;
  }
}

@media (max-width: 840px) {
  .signin-container--inner {
    padding: 60px 20px 40px;
  }
}
