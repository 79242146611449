.certificates-container {
  position: relative;
  padding-bottom: 30px;
}

.certificates-container--inner {
  position: relative;
  max-width: 1380px;
  display: flex;
  margin: auto;
  overflow: hidden;
  padding-right: 60px;
}

/* certificates-container-main-section */
section.certificates-container-main-section {
  position: relative;
  width: 100%;
  background: #fffdfd;
  padding: 0 16px;
  margin-left: 350px;
  margin-top: 130px;
}

.selected-company-name-wrapper {
  position: absolute;
  top: 60px;
  left: 366px;
  z-index: 40;
  display: flex;
  align-items: center;
  column-gap: 30px;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: #000000;
}

.certificates--back-button-wrapper button {
  background-color: inherit;
  border: none;
  padding: 0;
  /* transform: translateY(1px); */
}

/* Certificates Table section */
section.certificates-table-section {
  width: 100%;
  padding-bottom: 120px;
}

.certificates-filter-container {
  position: relative;
  border-bottom: 1px solid #a9a9bc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.certificates-filter-option {
  position: relative;
  width: 180px;
  padding: 12px 0;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  line-height: 20px;
  color: #9c9cac;
}

.filter-option--all {
  margin-left: 20px;
}

.active-filter-option {
  color: #1a0404;
}

.active-filter-option::after {
  content: "";
  position: absolute;
  width: 180px;
  height: 4px;
  left: 0;
  bottom: 0;
  /* Gradient */
  background: linear-gradient(
    270.14deg,
    #fd5e5e 40.6%,
    rgba(253, 94, 94, 0) 100.37%
  );
}

/* Table Top */
.certificates-table--top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Table Top Left */
.certificates-table--top--left-wrapper {
  display: flex;
  align-items: center;
}

/* .table-search-form-group  */
.table-search-form-group {
  position: relative;
  width: max-content;
}

.table-search-form-group input {
  width: 288px;
  height: 40px;
  border: 0.7px solid #ffb9b4;
  border-radius: 12px;
  padding: 12.5px 5px;
  padding-left: 44px;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  color: #828282;
}
.table-search-form-group input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

.table-search-form-group img {
  position: absolute;
  top: 10px;
  left: 12.5px;
}

/* filter-button-wrapper */
.filter-button-wrapper {
  position: relative;
  margin-left: 11px;
  margin-right: 25px;
}

.filter-button-wrapper button {
  background-color: inherit;
  border: none;
}

.active-filter-button {
  background-color: rgba(225, 225, 225, 0.45) !important;
}

/* bulk-download-button-wrapper */
.bulk-download-button-wrapper button {
  width: 162px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fe0000;
  border-radius: 10px;
  background-color: inherit;
  font-size: 16px;
  font-family: "Paralucent-stencil-medium";
  line-height: 20px;
  color: #fe0000;
}

/* Certificates Table */
.certificates-table-container {
  /* padding-bottom: 120px; */
}

/* .td-options--wrapper */
.td-options--wrapper {
  position: relative;
}

.more-options-button {
  background-color: inherit;
  border: none;
  width: max-content;
}

.certificate-more-options-wrapper {
  position: absolute;
  top: 30px;
  left: -75px;
  z-index: 70;
  width: 180px;
  min-height: 26px;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  padding: 10px 12px 8px;
}

.fifth-multiple-wrapper {
  /* top: -50px; */
  top: -8vh;
}

.more-options-modal-button-wrapper {
  width: 100%;
}

.more-options-modal-button-wrapper button {
  width: 100%;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  border: none;
  border-bottom: 1.5px solid rgba(130, 130, 130, 0.15);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #131313;
}

.request-cancellation-button-wrapper button {
  color: #fe0000;
}

.more-options-modal-button-wrapper button:hover {
  background-color: rgba(225, 225, 225, 0.25);
}

.request-cancellation-button-wrapper .loader-container > .spinner-container {
  padding: 0;
}

.request-cancellation-button-wrapper .ldio-ths3jlz8hwg > div {
  width: 18px;
  height: 18px;
  top: 3.5px;
  left: 2px;
}

.request-cancellation-button-wrapper
  .ldio-ths3jlz8hwg
  > div:nth-child(2)
  div:before,
.request-cancellation-button-wrapper
  .ldio-ths3jlz8hwg
  > div:nth-child(2)
  div:after {
  width: 0px;
  height: 0px;
}

.request-cancellation-button-wrapper .loadingio-spinner-dual-ring-tk7n0yezor {
  width: 20px;
  height: 22px;
}

/*  td-view-certificate--wrapper*/
.td-view-certificate--wrapper button {
  border: none;
  background-color: inherit;
  padding: 0 5px;
  margin: 0 15px;
}

.td-view-certificate--wrapper button img {
  height: 17px;
}

/* Media queries */
@media (min-width: 1400px) {
  .certificates-container--inner {
    max-width: 1500px;
  }
}

@media (min-width: 1500px) {
  .certificates-container--inner {
    max-width: 1700px;
  }
}
