.forgot-password-container {
  position: relative;
  background-color: #fffdfd;
}

.forgot-password-container--inner {
  position: relative;
  max-width: 1240px;
  margin: auto;
  padding: 85px 0 50px;
  display: flex;
  justify-content: space-between;
  column-gap: 56px;
  overflow: hidden;
}

.forgot-password-header-message {
  margin-top: 30px;
  text-align: center;
  color: rgb(95, 95, 95);
}

.forgot-password-form-wrapper {
  padding-top: 35px;
}

/* Media Queries */
@media (min-width: 1390px) {
  .forgot-password-container--inner {
    max-width: 1290px;
  }
}

@media (min-width: 1490px) {
  .forgot-password-container--inner {
    max-width: 1380px;
  }
}

@media (min-width: 1590px) {
  .forgot-password-container--inner {
    max-width: 1480px;
  }
}

@media (max-width: 1310px) {
  .forgot-password-container--inner {
    padding: 80px 25px 40px;
  }
}

@media (max-width: 840px) {
  .forgot-password-container--inner {
    padding: 60px 20px 40px;
  }
}
