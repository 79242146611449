body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

button:focus {
  outline: none;
}

@font-face {
  /* 300 */
  font-family: "Paralucent";
  font-weight: 300;
  src: url(./styles/fonts/Paralucent-Light.woff);
}

/* 400 */
/* @font-face {
  font-weight: 400;
  font-family: "Paralucent";
  src: url(./styles/fonts/Moderat-Regular.ttf);
} */

@font-face {
  /* 500 */
  font-weight: 500;
  font-family: "Paralucent";
  src: url(./styles/fonts/Paralucent-Medium.woff);
}

@font-face {
  /* 600 */
  font-weight: 600;
  font-family: "Paralucent";
  src: url(./styles/fonts/Paralucent-Bold.woff);
}

@font-face {
  /* 700 */
  font-weight: 700;
  font-family: "Paralucent";
  src: url(./styles/fonts/Paralucent-Heavy.ttf);
}

/* Stencil Fonts */
@font-face {
  /* Stencil light */
  font-weight: 300;
  font-family: "Paralucent-stencil-light";
  src: url(./styles/fonts/ParalucentStencil-ExtraLight.ttf);
}

@font-face {
  /* Stencil light */
  font-weight: 500;
  font-family: "Paralucent-stencil-medium";
  src: url(./styles/fonts/ParalucentStencil-Medium.ttf);
}

html {
  background-color: #fffdfd;
}

/* Moderat-- font override */
.app {
  height: 100vh;
  background-color: #fffdfd;
}

.app,
textarea,
input,
select {
  font-family: "Paralucent";
}

input:focus,
textarea:focus,
select:focus {
  /* outline: 0.4px solid #131313 !important; */
}

button {
  font-family: "Paralucent";
  cursor: pointer;
}

/* Remove the default angle icon in select dropdown */
select {
  /* For Firefox */
  -webkit-appearance: none;
  /* for Chrome */
  -moz-appearance: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

/* For IE10 */
select::-ms-expand {
  display: none;
}

/*  */
.form-group {
  position: relative;
}

select:focus {
  outline: 0.1px solid #131313;
}

.below-table-loader-wrapper {
  margin-top: 20px;
}

/*  */
/*  */
.none {
  display: none !important;
}

.overflow-hidden {
  overflow: hidden;
  height: 100vh;
}

.gray-overlay {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  z-index: 50;
  transition: all ease-in-out 0.5s;
}

.app-error-success-message-wrapper {
  top: 80px !important;
}

.submit-btn-loading-spinner-wrapper {
  margin-left: 5px;
}
