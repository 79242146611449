.dashboard-container {
  position: relative;
  padding-bottom: 30px;
}

.dashboard-container--inner {
  position: relative;
  max-width: 1380px;
  display: flex;
  margin: auto;
  overflow: hidden;
  padding-right: 60px;
}

/* dashboard-container-main-section */
section.dashboard-container-main-section {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background: #fffdfd;
  margin-left: 350px;
  padding: 0 16px;
  margin-top: 180px;
}

/* Row ONE section */
section.dashboard--row-one-section {
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
  row-gap: 20px;
}

.dashboard-top-box-wrapper {
  width: 315px;
  min-height: 145px;
  max-height: 160px;
  border-radius: 8px;
  color: #131313;
  border: double 2px transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(90deg, rgba(84, 86, 90, 0.33), rgba(254, 0, 0, 0.29));
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.dashboard-top-box-wrapper--inner {
  padding: 15px 0 0 16px;
}

/* .dashboard-top-box--top-wrapper { */
.dashboard-top-box--top-wrapper {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.dashboard--box-title-wrapper {
  font-size: 16px !important;
}

.box-title-wrapper span {
  font-size: 13px;
  font-weight: 500;
  line-height: 14px;
  color: #54565a;
  padding-left: 14px;
}

/* dashboard-top-box--middle-wrapper */
.dashboard-top-box--middle-wrapper {
  padding-left: 45px;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  color: #131313;
  margin-top: 12px;
}

.view-history-button-wrapper {
  margin-top: 10px;
}

.view-history-button-wrapper button {
  width: 150px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fe0000;
  border-radius: 10px;
  background-color: inherit;
  font-size: 16px;
  font-family: "Paralucent-stencil-medium";
  line-height: 20px;
  color: #fe0000;
}

.dashboard-top-box--bottom-wrapper {
  display: flex;
  column-gap: 16px;
  padding-left: 45px;
  margin-top: 16px;
}

.date-filter-dropdown-form-group {
  position: relative;
  width: max-content;
}

.angle-img-wrapper {
  position: absolute;
  right: 8px;
  top: 0.5px;
  pointer-events: none;
}

.date-filter-dropdown-form-group select {
  width: 106px;
  height: 23px;
  background: #fff5f5;
  border-radius: 4px;
  border: none;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #54565a;
  padding: 4px 8px;
}

.certificates-generated-percent-change {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #219653;
}

.arrow-display-img-wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #c9ffe0;
  margin-left: 5px;
}

/* view-more-certificates-button-wrapper */
.view-more-certificates-button-wrapper button {
  width: 106px;
  height: 25px;
  background: #fff5f5;
  border-radius: 4px;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #131313;
  padding: 4px 8px;
}

.request-units-button-wrapper button {
  width: 220px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fe0000;
  border-radius: 10px;
  background-color: inherit;
  font-family: "Paralucent-stencil-medium";
  font-size: 16px;
  line-height: 20px;
  color: #fe0000;
}

/* Row TWO Section */
section.dashboard--row-two-section {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}

.dashboard--row-two-left-container {
  max-width: 65%;
  width: 100%;
}

/* Left Container */
section.latest-certificates-table-section {
  width: 100%;
}

.latest-certificates-table--top-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 24px;
}

.table-title-wrapper {
  font-weight: 500;
  line-height: 19px;
  color: #131313;
}

/* Right Container */
.dashboard--row-two-right-container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

/* .premium-deposit-box-wrapper */
.premium-deposit-box-wrapper {
  width: 309px;
  height: 236px;
  background: #ffffff;
  border-radius: 12px;
  padding: 24px 21px 16px;
}

.premium-deposit-box-title-wrapper,
.referral-link-title-wrapper {
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  color: #131313;
}

/* premium-deposit-box--inner-wrapper */
.premium-deposit-box--inner-wrapper {
  width: 100%;
  height: 160px;
  background: #fff5f5;
  border-radius: 12px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
}

.wallet-balance-title-wrapper {
  font-weight: 500;
  line-height: 19px;
  color: #131313;
}

/* .wallet-balance-view-box-wrapper { */
.wallet-balance-view-box-wrapper {
  position: relative;
  width: 208px;
  height: 62px;
  background: #ffffff;
  border-radius: 12px;
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.wallet-balance-view-wrapper {
  padding-left: 23px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #131313;
}

.hidden-balance-icon-wrapper {
  position: absolute;
  height: 15.5px;
  right: 20.5px;
  top: 18px;
  cursor: pointer;
}

/* top-up-wallet-button-wrapper */
.top-up-wallet-button-wrapper button {
  border: none;
  background-color: inherit;
  font-family: "Paralucent-stencil-medium";
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #fe0000;
  margin-top: 12px;
}

/* .referral-link-clipboard-container */
.referral-link-clipboard-container {
}

.referral-link-clipboard-container--inner {
  width: 266px;
  height: 67px;
  padding: 9px 12px;
  background: #fff5f5;
  border-radius: 12px;
  margin-top: 12px;
}

.referral-link-clipboard-wrapper {
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 14px;
  border-radius: 12px;
  padding: 0 16px;
  font-weight: 300;
  line-height: 19px;
  text-decoration-line: underline;
  color: #fe0000;
}

.referral-link-clipboard-button-wrapper button {
  width: max-content;
  height: max-content;
  padding: 0;
  background-color: inherit;
  border: none;
}

.referral-link-clipboard-button-wrapper button img {
  transform: translateY(3px);
}

/* Rewards and Referrals box */
.rewards-referrals-box-container {
  background-image: url("../../images/referral-box-img.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: 250px;
}

.more {
  display: flex;
  align-items: baseline;
}

/* Media queries */
@media (min-width: 1400px) {
  .dashboard-container--inner {
    max-width: 1500px;
  }
}

@media (min-width: 1500px) {
  .dashboard-container--inner {
    max-width: 1700px;
  }
}
