.policy-stage-container {
}

/* policy processing form wrapper */
.policy-processing-form-wrapper {
  margin-top: 30px;
}

/* policy-processing-form--top-wrapper */
.policy-processing-form--top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bulk-upload-option-wrapper {
  width: 379px;
  height: 60px;
  background: #fff5f5;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 24px;
  padding: 0 15px;
}

.import-excel-button-wrapper {
  position: relative;
  width: 232px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fe0000;
  border-radius: 10px;
}

.import-excel-button-wrapper input {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.import-excel-button-wrapper label {
  font-family: "Paralucent-stencil-medium";
  line-height: 20px;
  color: #fe0000;
}

.excel-template-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.excel-template-button-wrapper a {
  background-color: inherit;
  border: none;
  padding: 0;
  margin-top: 6px;
  font-family: "Paralucent-stencil-medium";
  color: #fe0000;
  text-decoration: underline !important;
  font-size: 15px;
}

/* Form */
.policy-processing-form-wrapper form {
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  row-gap: 35px;
  flex-wrap: wrap;
}

.summary-page-form-wrapper {
  margin-bottom: 40px;
}

.policy-processing-form-wrapper .form-group,
.summary-page-form-wrapper .form-group {
  width: 440px;
}

.policy-processing-form-wrapper .form-group input,
.policy-processing-form-wrapper .form-group select,
.summary-page-form-wrapper .form-group select {
  width: 100%;
}

.cargo-description-form-group {
  min-width: 100%;
  display: flex;
  flex-grow: 1;
}

.policy-processing-form-wrapper .form-group textarea {
  width: 100%;
  height: 135px;
}

.policy-processing-form-wrapper
  .form-group
  input[type="number"]::-webkit-inner-spin-button,
.policy-processing-form-wrapper
  .form-group
  input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
}

/* Form Page Bottom Wrapper */
.policy-form-page-bottom-wrapper {
  width: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin-top: 120px;
}

.naira-equiv-box-wrapper {
  width: 436px;
  height: 59px;
  background: #f1f1f1;
  border-radius: 15px;
  padding-left: 35px;
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.naira-equiv-key-wrapper,
.naira-equiv-value-wrapper {
  font-weight: 300;
  line-height: 19px;
  color: #131313;
}

.naira-equiv-value-wrapper {
  font-weight: 500;
}

/* MEDIA QUERIES */
@media (max-width: 1360px) {
  .policy-processing-form-wrapper .form-group {
    width: 380px;
  }
}

@media (max-width: 1240px) {
  .policy-processing-form-wrapper .form-group {
    width: 340px;
  }
}

@media (max-width: 1155px) {
  .policy-processing-form-wrapper .form-group {
    width: 310px;
  }
}

@media (max-width: 1095px) {
  .policy-processing-form-wrapper .form-group {
    max-width: 480px;
    width: 100%;
  }
}

.text {
  color: #fe0000;
  margin-top: 5px;
}

.modal-container {
  border: 1px solid;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.btn-modal {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}

.btn-modal button:nth-child(2) {
  background-color: #fe0000;
  color: #f1f1f1;
  width: 35%;
  border: none;
}

.btn-modal button:nth-child(1) {
  width: 35%;
  border: 1px solid #fe0000;
  color: #fe0000;
}

/* .overlay {
  background: rgba(49,49,49,0.8);
} */

.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 700px;
  min-width: 300px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

.info {
  padding: 2px;
  font-weight: lighter;
}
