.marine-policy-process-summary-container {
  margin-top: 12px;
  color: #131313;
}

.marine-policy-process-summary--header-wrapper {
  height: 174px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  line-height: 22px;
}

.form-page-header-left-wrapper {
  display: flex;
  align-items: center;
}

.add-another-cargo-button-wrapper button {
  width: 200px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fe0000;
  border: none;
  border-radius: 8px;
  border: none;
  font-family: "Paralucent-stencil-medium";
  font-size: 15px;
  line-height: 20px;
  color: #fffdfd;
  margin-left: 60px;
}

/* Process details */
.process-details--summary-wrapper {
  width: 371px;
  min-height: 100%;
  background: #fff5f5;
  border-radius: 16px 0px 0px 16px;
  padding: 20px 28px;
}

/* .company-name-propmt-container */
.company-name-propmt-container {
  margin-bottom: 20px;
  color: #fe0000;
}

/* Total premium plan */
.process-premium-plan--summary-wrapper {
  width: 371px;
  min-height: 100%;
  background: #fff9f9;
  /* background: red; */
  border-radius: 0 16px 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.policy-detail-info-wrapper {
  display: flex;
  align-items: center;
}

.policy-detail-info-wrapper {
  margin-top: 8px;
}

.policy-detail-title {
  width: 150px;
}

.policy-detail-value {
  width: 202px;
  height: 42px;
  background: #ffffff;
  border-radius: 4px;
  padding: 10px 20px;
}

.process-premium-plan--summary-wrapper--inner {
  width: 318px;
  height: 140px;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.total-premium-plan-value {
  margin-top: 27px;
  font-size: 24px;
  line-height: 28px;
}

/* .summary-section-title-wrapper */
.summary-section-title-wrapper {
  font-weight: 600;
}

.cargo-summary-section-title-wrapper {
  display: flex;
  align-items: center;
  margin-top: 25px;
  column-gap: 130px;
}

.cargo-summary-section-title-wrapper button {
  width: 150px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fe0000;
  border: none;
  border-radius: 8px;
  border: none;
  font-family: "Paralucent-stencil-medium";
  font-size: 15px;
  line-height: 18px;
  color: #fffdfd;
}

/* .cargos-summary-container */
.cargos-summary-container {
  margin-top: 20px;
}

.policy-form-edit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #131313;
  /* padding-left: 155px; */
  margin-top: 40px;
}

/* Edit Box Wrapper */
.policy-form-value-edit-wrapper {
  display: flex;
  align-items: center;
  column-gap: 80px;
  margin-top: 12px;
}

/* Title Wrapper */
.policy-form-edit--title-wrapper {
  width: 170px;
  height: 19px;
  font-weight: 300;
  line-height: 19px;
}

/* Value wrapper */
.policy-form-edit--value-wrapper {
  width: 417px;
  height: 54px;
  background: #fff9f9;
  border-radius: 8px;
  padding: 17px 48px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submit-marine-policy-button-wrapper,
.top--submit-marine-policy-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-marine-policy-button-wrapper {
  margin-top: 100px;
}

.submit-marine-policy-button-wrapper button,
.top--submit-marine-policy-button-wrapper button {
  width: 231px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fe0000;
  border: none;
  border-radius: 10px;
  border: none;
  font-family: "Paralucent-stencil-medium";
  font-size: 16px;
  line-height: 20px;
  color: #fffdfd;
}

.currency {
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
}
