.contact-admin-container {
  position: relative;
  padding-bottom: 30px;
}

.contact-admin-container--inner {
  position: relative;
  max-width: 1380px;
  display: flex;
  margin: auto;
  overflow: hidden;
  padding-right: 60px;
}

/* contact-admin-container-main-section */
section.contact-admin-container-main-section {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background: #fffdfd;
  margin-left: 350px;
  padding: 0 16px;
  margin-top: 130px;
}

/* .contact-admin--top-container { */
.contact-admin--top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 24px;
}

.contact-admin-top-box-wrapper {
  width: 300px;
  height: 140px;
  border-radius: 8px;
  color: #131313;
  border: double 2px transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(90deg, rgba(84, 86, 90, 0.33), rgba(254, 0, 0, 0.29));
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.contact-admin-top-box-wrapper--inner {
  padding: 24px 5px 0px 24px;
}

/* box-title-wrapper */
.box-title-wrapper {
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
}

/* box-value-wrapper */
.box-value-wrapper {
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
}

.contact-boxes-value-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  margin-top: 12px;
}

.box-value-wrapper a {
  text-decoration: none;
  color: #131313;
}

.message-to-admin-form-wrapper {
  margin-top: 32px;
}

.message-form-group {
  width: 100%;
}

.message-form-group textarea {
  width: 100%;
  height: 342px;
  background: #ffffff;
  border: 1px solid #fff5f5;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(19, 19, 19, 0.02);
  padding: 28px 30px;
  resize: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #54565a;
}

.message-form-group textarea::placeholder {
  font-style: italic;
  font-weight: 300;
}

.submit-message-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
}

.submit-message-button-wrapper button {
  width: 162px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fe0000;
  border-radius: 10px;
  border: none;
  font-family: "Paralucent-stencil-medium";
  font-size: 16px;
  line-height: 20px;
  color: #fffdfd;
}

/* Media queries */
@media (min-width: 1400px) {
  .contact-admin-container--inner {
    max-width: 1500px;
  }
}

@media (min-width: 1500px) {
  .contact-admin-container--inner {
    max-width: 1700px;
  }
}
