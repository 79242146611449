.onboarding-stage-five-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.congratulations-img-wrapper {
  margin-top: 120px;
}

.signup-success-main-text-wrapper {
  margin: 24px 0 28px;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #131313;
}

.signup-success-sub-text-wrapper {
  max-width: 425px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #54565a;
}
