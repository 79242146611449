.navbar-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
  background: #fffdfd;
  box-shadow: 0px 4px 4px rgba(18, 18, 18, 0.1);
}

.navbar-container--inner {
  max-width: 1240px;
  height: 85px;
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.company-logo-wrapper {
  cursor: pointer;
}

.company-logo-wrapper img {
  height: 38px;
}

.auth-navbar--signin-button-wrapper button {
  width: 216px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #fe0000;
  border-radius: 10px;
  background-color: inherit;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fe0000;
}

/* Media Queries */
@media (min-width: 1390px) {
  .navbar-container--inner {
    max-width: 1290px;
  }
}

@media (min-width: 1490px) {
  .navbar-container--inner {
    max-width: 1380px;
  }
}

@media (min-width: 1590px) {
  .navbar-container--inner {
    max-width: 1480px;
  }
}

@media (max-width: 1310px) {
  .navbar-container--inner {
    padding: 0 25px;
    height: 80px;
  }
}

@media (max-width: 840px) {
  .navbar-container--inner {
    padding: 0 20px;
  }
}
