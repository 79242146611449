.policy-form-two-header-box-wrapper {
  width: 484px;
  height: 92px;
  background: #fff5f5;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Paralucent-stencil-medium";
  font-size: 20px;
  line-height: 25px;
  color: #54565a;
  margin: 0 auto;
}

.policy-two-five-form-container {
  display: flex !important;
  justify-content: center !important;
}
