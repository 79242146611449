.form-group {
  position: relative;
}

.form-group textarea {
  height: 57px;
  border: 0.6px solid #131313;
  border-radius: 15px;
  /* outline: none; */
  display: flex;
  padding: 20px 35px;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  color: #131313;
  resize: none;
}

.form-group textarea:valid + span.placeholder,
.form-group textarea:focus + span.placeholder {
  transform: scale(0.9) translateY(-30px) translateX(20px);
  background: #fff;
}

.form-group textarea:focus {
  outline: 0.1px solid #131313;
}

.form-group textarea:focus + span.placeholder {
  color: #808080;
}
