.onboarding-container {
  position: relative;
  padding-bottom: 30px;
}

.onboarding-container--inner {
  position: relative;
  max-width: 1380px;
  display: flex;
  margin: auto;
  overflow: hidden;
  padding-right: 60px;
}

/* onboarding-container-main-section */
section.onboarding-container-main-section {
  position: relative;
  width: 100%;
  background: #fffdfd;
  margin-left: 350px;
  padding: 0 16px;
  margin-top: 130px;
}

/* Media queries */
@media (min-width: 1400px) {
  .onboarding-container--inner {
    max-width: 1500px;
  }
}

@media (min-width: 1500px) {
  .onboarding-container--inner {
    max-width: 1700px;
  }
}
