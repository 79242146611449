.signup-container {
  position: relative;
  background-color: #fffdfd;
}

.signup-container--inner {
  position: relative;
  max-width: 1240px;
  margin: auto;
  padding: 85px 0 50px;
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
  overflow: hidden;
}

.auth-error-success-message-wrapper {
  top: 105px !important;
}

.onboarding-stage-two-action-button-wrapper {
  margin-top: 40px;
}

.back-button-wrapper button,
.continue-button-wrapper button {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.back-button-wrapper button {
  width: 216px;
  background-color: inherit;
  border: 1.5px solid #fe0000;
  color: #fe0000;
}

.continue-button-wrapper button {
  width: 245px;
  background: #fe0000;
  border: none;
  color: #fffdfd;
}

/*  */
/*  */
/* Media Queries */
@media (min-width: 1390px) {
  .signup-container--inner {
    max-width: 1290px;
  }
}

@media (min-width: 1490px) {
  .signup-container--inner {
    max-width: 1380px;
  }
}

@media (min-width: 1590px) {
  .signup-container--inner {
    max-width: 1480px;
  }
}

@media (max-width: 1310px) {
  .signup-container--inner {
    padding: 80px 25px 40px;
  }
}

@media (max-width: 840px) {
  .signup-container--inner {
    padding: 60px 20px 40px;
  }
}
