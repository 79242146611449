/*  td-view-certificate--wrapper*/
.td-dashboard-view-certificate-wrapper {
  display: flex;
  align-items: center;
}

.td-resume-certificate--wrapper button {
  border: none;
  background-color: inherit;
  padding: 0 5px;
  margin: 0 15px;
}

.td-resume-certificate--wrapper button img {
  height: 22px;
}

.td-shipping--wrapper {
  max-width: 350px !important;
  min-width: 150px !important;
}
